// .results-upload {
//   background-color: #fafbfc;
//   font-size: 14px;
  .upload-result-container-outer {
    padding-top: 30px;
    .content-wrapper {
      width: 100%;
      //max-width: 800px;
      max-width: 450px;
      background-color: #fff;
      margin: 0 auto;
      //padding: 30px 20px;
      padding: 35px 30px;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02), 0 3px 6px rgba(0, 0, 0, 0.09);
      .title {
        font-size: 22px;
        color: #003765;
        padding-bottom: 20px;
      }
      .panel-options {
        .select-tasks-radio-group-label {
          color: #003765;
          padding-bottom: 0;
          margin-bottom: 0;
          font-weight: 500;
        }
        .select-tasks-radio-group {
          margin-top: 10px;
        }
      }

      // scan barcode styles
      .scan-container {
        &.no-result {
          margin-bottom: 50px;
        }
        .label {
          color: #003765;
          font-weight: 500;
        }
        .container-row {
          .flex-1 {
            flex: 1;
          }
          .synlab-input-field {
            .input-group {
              height: 45px;
              border: 1px solid #d4d9df;
              border-radius: 4px;
              overflow: hidden;

              input {
                height: 100%;
                border: 0;
              }
              .btn {
                background-color: #003765;
                position: absolute;
                right: 2px;
                top: 2px;
                bottom: 2px;
                padding-left: 10px;
                padding-right: 10px;
                border: 0;
                z-index: 5;
                min-width: 40px;
              }
            }
          }
          .scanner-actions {
            align-items: center;
            .scan-label {
              font-size: 15px;
              color: #0077ad;
              font-weight: 700;
            }
            .btn {
              background-color: transparent;
              border: 0;
              color: #0077ad;
              img {
                height: 20px;
                position: relative;
                top: -1px;
              }
            }
          }
        }
      }

      // profile info wrapper
      .profile-info-wrapper {
        margin-top: 20px;
        .label {
          color: #003765;
          font-weight: 500;
          padding-bottom: 5px;
          font-size: 15px;
        }
        .info-rows {
          .row {
            padding-bottom: 10px;
            .field-label {
              color: #0077ad;
            }
            .value {
              svg {
                margin-left: -10px;
                rect {
                  fill: #003765;
                }
              }
            }
          }
        }
      }

      // results
      .result-container {
        margin-top: 20px;
        &.no-result {
          margin-bottom: 50px;
        }
        .label {
          color: #003765;
          font-weight: 500;
          padding-bottom: 5px;
          font-size: 15px;
        }
        .selected-result {
          padding-top: 20px;
          padding-bottom: 10px;
          .result-value {
            padding-left: 5px;
            color: #a3abb1;
            font-weight: 500;
            &.positive {
              color: #be1622;
            }
            &.negative {
              color: #68971c;
            }
          }
          .description {
            padding-top: 10px;
          }
        }

        .mat-chip-list-wrapper {
          .mat-chip {
            border: 2px solid #a3abb1;
            background-color: transparent !important;
            min-height: 40px;
            padding: 7px 25px;
            border-radius: 24px;
            cursor: pointer;
            &.selected {
              background-color: #a3abb1 !important;
              color: #fff;
            }

            &[data-value="negative"] {
              border-color: #68971c;
              &.selected {
                background-color: #68971c !important;
              }
            }
            &[data-value="positive"] {
              border-color: #be1622;
              &.selected {
                background-color: #be1622 !important;
              }
            }
            &:focus {
              &::after {
                display: none;
              }
            }
          }
        }
      }

      // actions container
      .actions-container {
        margin-top: 20px;
        margin-left: -20px;
        margin-right: -20px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        border-top: 1px solid #e3e6ea;
        .mat-mdc-checkbox {
          .mdc-form-field {
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
        button {
          background-color: #003765;
          border: 0;
          min-height: 42px;
        }
      }
    }
  }

  // styles for mat-dialog / scanner dialog
  // .cdk-overlay-container {
  //   .cdk-overlay-pane {
  //     max-width: 90vw !important;
  //   }
  //   .mat-dialog-content {
  //     padding: 0 !important;
  //   }
  // }

  // styles for snackbar
  .mat-snack-bar-container {
    margin-top: 62px;
    margin-right: 2px;
    border-radius: 1px;
    color: #fff;
    .mat-simple-snackbar {
      color: #fff;
    }

    &.panel-success {
      background-color: #0077ad;
    }
    &.panel-warning {
      background-color: #ffac00;
    }
    &.panel-error {
      background-color: #d43532;
    }
  }
//}


.lab-order{
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #ffffff;
  }
}
