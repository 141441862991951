@import "./variables";
@import "./tab.scss";
@import "./card.scss";

/*
* text colors
*/
.pl-text-color-1 {
  color: $cs1;
}
.pl-text-color-2 {
  color: $cs2;
}
.pl-text-color-3 {
  color: $cs3;
}
.pl-text-color-4 {
  color: $cs4;
}
.pl-text-color-5 {
  color: $cs5;
}

/*
* borders
*/
.pl-border-1 {
  border: 1px solid $cs1;
}

/*
* box-shadow
*/
.pl-box-shadow-1 {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(121, 133, 142, 0.42);
  -moz-box-shadow: 0px 0px 5px 0px rgba(121, 133, 142, 0.42);
  box-shadow: 0px 0px 5px 0px rgba(121, 133, 142, 0.42);
  transition: box-shadow ease-in 0.12s;
}
.pl-box-shadow-1-hover:hover {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(121, 133, 142, 0.52);
  -moz-box-shadow: 0px 0px 15px 0px rgba(121, 133, 142, 0.52);
  box-shadow: 0px 0px 15px 0px rgba(121, 133, 142, 0.52);
  transition: box-shadow ease-in 0.12s;
}
.pl-box-shadow-2 {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(121, 133, 142, 0.31);
  -moz-box-shadow: 0px 5px 10px 0px rgba(121, 133, 142, 0.31);
  box-shadow: 0px 5px 10px 0px rgba(121, 133, 142, 0.31);
}

/*
* background colors
*/
.pl-bg-1 {
  background-color: $cs6;
}
.pl-bg-2 {
  background-color: $cs0;
}
.pl-bg-3 {
  background-color: $cs7;
}

/*
* logo sizes
*/
.pl-logo-1 {
  max-height: 35px;
}

.font-color-secondary {
  color: #0077AD;
}
.font-color-black { color: #000; }
.font-color-white { color: #fff; }
/*
* font weight scale
*/
@each $num in $font-weight-list {
  .font-weight-#{$num} {
    font-weight: $num;
  }
}

/**
* primary font color scale
* primary bg color scale
*/
@each $color, $num in $primary-list {
  .font-color-primary-#{$num} {
    color: $color;
  }
  .bg-color-primary-#{$num} {
    background-color: $color;
  }
}

/**
* yellow font color scale
* yellow bg color scale
*/
@each $color, $num in $yellow-list {
  .font-color-yellow-#{$num} {
    color: $color;
  }
  .bg-color-yellow-#{$num} {
    background-color: $color;
  }
}

/**
* green font color scale
* green bg color scale
*/
@each $color, $num in $green-list {
  .font-color-green-#{$num} {
    color: $color;
  }
  .bg-color-green-#{$num} {
    background-color: $color;
  }
}

/**
* grey font color scale
* grey bg color scale
*/
@each $color, $num in $grey-list {
  .font-color-grey-#{$num} {
    color: $color;
  }
  .bg-color-grey-#{$num} {
    background-color: $color;
  }
  .border-color-grey-#{$num} {
    border-color: $color;
  }
}
