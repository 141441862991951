
:root {
  --gp-bg-lightblue-2: #F7F8F9;
  --gp-text-highlights-primary: #0478ae;
  --gp-button-blue-6: #0478ae;
}

body .gp-prevention-wellness {

  width: 100vw;

  .analysis-header-container {
    background: none!important;
  }

  .text-primary {
    color: #0478ae!important;
  }

  .slider {
    position: initial;
    background: none;
  }

  .navigation {
    padding-left: 2.5vw;
  }
}
