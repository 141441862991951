// material palettes
$dark-primary-text: #ffffff;
$light-primary-text: #000000;
$danger-color: #BE1320;
$primary-blue: #003765;
$primary-skyblue: #0077AD;
$primary-blue-border: #BFDDEA;
$primary-blue-border-dark: #0077AD;
$primary-blue-dark: #003765;
$primary-blue-skyblue-5: #F2F8FB;
$primary-blue-skyblue-10: #E6F1F7;
$primary-blue-skyblue-15: #D9EBF3;
$primary-blue-checkbox: #BFCDD8;
$primary-blue-90: #1A4B74;
$primary-blue-70: #4D7393;
$primary-dark-15: rgba(0, 119, 173, 0.15);
$primary-dark-50: #809BB2;
$primary-blue-card-box: rgba(0, 119, 173, 0.08);

$primary-palette: (
  50: #e2eef4,
  100: #b7d5e6,
  200: #8cbbd5,
  300: #65a1c4,
  400: #498fbb,
  500: #2f7eb1,
  600: #2472a6,
  700: #196295,
  800: #0f5284,
  900: #003865,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$secondary-yellow: #F9B232;
$secondary-palette: (
  50: #e1f5fb,
  100: #b3e4f5,
  200: #82d3ef,
  300: #54c1e8,
  400: #33b4e5,
  500: #14a8e2,
  600: #0b9ad4,
  700: #0087c1,
  800: #0076ad,
  900: #00578c,
  A100: #84ffff,
  A200: #18ffff,
  A400: #00e5ff,
  A700: #00b8d4,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

// colors
$cs0: #ffffff;
$cs1: #ededed;
$cs2: #79858e;
$cs4: #454d58;
$cs3: #397ea6;
$cs5: #0a3f6b;
$cs6: #fafbfc;
$cs7: #e5ebef;

$synlab-secondary-text: map-get($secondary-palette, 800);

/** breakpoints */
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;


$content-width-1: 1338px;
$content-width-2: 665px;

$poolOrderContentWidth: $content-width-1;
$queryBarcodeContentWidth: $content-width-1;
$individualOrderContentWidth: $content-width-1;

$parentChildContetWidth: $content-width-2;

// border width scale
$border-width-1: 1px;
$border-width-2: 2px;
$border-width-4: 4px;

$border-width-list:
    $border-width-1 1,
    $border-width-2 2,
    $border-width-4 4;

// font size scale
$font-size-1: .75rem;      // 12px
$font-size-2: .875rem;     // 14px
$font-size-3: 1rem;        // 16px
$font-size-4: 1.125rem;    // 18px
$font-size-5: 1.25rem;     // 20px
$font-size-6: 1.5rem;      // 24px
$font-size-7: 1.875rem;    // 30px
$font-size-8: 2.25rem;     // 36px
$font-size-9: 3rem;        // 48px
$font-size-10: 4rem;       // 72px

$font-size-list:
    $font-size-1 1,
    $font-size-2 2,
    $font-size-3 3,
    $font-size-4 4,
    $font-size-5 5,
    $font-size-6 6,
    $font-size-7 7,
    $font-size-8 8,
    $font-size-9 9,
    $font-size-10 10;

// primary color scale
$primary-100: hsl(191, 49%, 93%);
$primary-200: hsl(200, 65%, 91%);
$primary-300: hsl(195, 25%, 72%);
$primary-400: hsl(200, 36%, 41%);
$primary-500: hsl(195, 56%, 30%);
$primary-600: hsl(199, 100%, 19%);
$primary-700: hsl(212, 100%, 17%);
$primary-800: hsl(226, 100%, 15%);
$primary-900: hsl(240, 100%, 13%);

$primary-list:
    $primary-100 100,
    $primary-200 200,
    $primary-300 300,
    $primary-400 400,
    $primary-500 500,
    $primary-600 600,
    $primary-700 700,
    $primary-800 800,
    $primary-900 900;


// font-weight
$font-weight-list: 100, 200, 300, 400, 500, 600, 700, 800, 900;

// green / success color scale
$green-100: #EFF6F1;
$green-200: #C6F6D5;
$green-300: #9AE6B4;
$green-400: #68D391;
$green-500: #48BB78;
$green-600: #38A169;
$green-700: #2F855A;
$green-800: #276749;
$green-900: #22543D;

$green-list:
    $green-100 100,
    $green-200 200,
    $green-300 300,
    $green-400 400,
    $green-500 500,
    $green-600 600,
    $green-700 700,
    $green-800 800,
    $green-900 900;

// yellow / warning color scale
$yellow-100: #FEFFE2;
$yellow-200: #FEFCBF;
$yellow-300: #FAF089;
$yellow-400: #F6E05E;
$yellow-500: #ECC94B;
$yellow-600: #D69E2E;
$yellow-700: #B7791F;
$yellow-800: #975A16;
$yellow-900: #744210;

$yellow-list:
    $yellow-100 100,
    $yellow-200 200,
    $yellow-300 300,
    $yellow-400 400,
    $yellow-500 500,
    $yellow-600 600,
    $yellow-700 700,
    $yellow-800 800,
    $yellow-900 900;


// grey / bg / inactive color scale
$grey-100: #fafbfc;
$grey-200: #F2F5F7;
$grey-300: #C4C4C4;
$grey-400: #CBD5E0;
$grey-500: #A0AEC0;
$grey-600: #718096;
$grey-700: #4A5568;
$grey-800: #2D3748;
$grey-900: #1A202C;

$grey-list:
    $grey-100 100,
    $grey-200 200,
    $grey-300 300,
    $grey-400 400,
    $grey-500 500,
    $grey-600 600,
    $grey-700 700,
    $grey-800 800,
    $grey-900 900;


// radius scale
$radius-0: 0;
$radius-1: 0.125rem;
$radius-2: 0.25rem;
$radius-3: 0.375rem;
$radius-4: 0.5rem;
$radius-5: 1rem;
$radius-full: 9999px;


$radius-list: $radius-0 0, $radius-1 1, $radius-2 2, $radius-3 3, $radius-4 4, $radius-5 5, $radius-full full;

$letter-space-1: -0.05em;
$letter-space-2: -0.025em;
$letter-space-3: 0;
$letter-space-4: 0.025em;
$letter-space-5: 0.05em;
$letter-space-6: 0.1em;

$letter-space-list:
    $letter-space-1 tighter,
    $letter-space-2 tight,
    $letter-space-3 normal,
    $letter-space-4 wide,
    $letter-space-5 wider,
    $letter-space-6 widest;
