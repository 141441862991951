$primary: #003765;
@use '@angular/material' as mat;
@import "app/themes/_variables.scss";
@import "./app/themes/themes.scss";
@import "~swiper/swiper-bundle.min.css";
@import "@angular/cdk/overlay-prebuilt.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@include mat.all-component-typographies();
@include mat.core();
$ClientApp-primary: mat.define-palette($primary-palette);
$ClientApp-accent: mat.define-palette($secondary-palette, A200, A100, A400);
$ClientApp-warn: mat.define-palette(mat.$red-palette);
$ClientApp-theme: mat.define-light-theme((color: (primary: $ClientApp-primary,
        accent: $ClientApp-accent,
        warn: $ClientApp-warn )));
@include mat.all-component-themes($ClientApp-theme);

body {
  min-height: 100%;
  background-color: #fcfdfd;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1 {
  font-weight: 300;
}

h3 {
  color: lighten(#000000, 25%);
  /* font-weight: 400; */
}

hr {
  margin: 25px 0 30px 0;
}

.card-header {
  font-weight: bold;
}

.nav-color {
  background-color: #4572b7;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.flx-1 {
  flex: 1;
}

.mrg-auto {
  margin: auto;
}

.index {
  margin-top: 70px;
}

.user-list {
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
  display: flex;
}

.user-filter {
  margin: 18px 0 25px 0;
}

.mrg-btn-3 {
  margin-bottom: 20px;
}

.navbar-appname {
  font-size: smaller;
  margin-top: -4px;
}

/*toogle*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

body,
html {
  width: 100%;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  .container-fluid {
    max-width: 1900px;
  }
}

.page-box {
  background-color: #ffffff;
  margin-top: 30px;
  padding: 30px 20px;
  -webkit-box-shadow: 0px 0px 44px -14px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 44px -14px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 44px -14px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  height: auto !important;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: white;
  overflow: hidden;
  border: 3px solid #0077ad;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: black;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 15px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: white;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #0077ad;
  color: white;
}

.patientrecord {
  margin-top: 25px;
  margin-bottom: 30px;
}

.topbar {
  margin-top: 25px;
}

.logo {
  width: 125px;
}

.nav-height {
  padding-top: 0px;
  max-height: 61px;
}

.nav-height .navbar-nav {
  margin-top: 8px;
}

/* Style inputs with type="text", select elements and textareas */
* {
  box-sizing: border-box;
}


/* Style the submit button with a specific background color etc */
input[type="search"] {
  background-color: #0077ad;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker color */
input[type="search"]:hover {
  background-color: #0077ad;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

form {
  .table.table-striped {
    thead {
      th {
        color: #323230;
        // font-weight: normal;
      }
    }

    tbody {
      tr {
        td {
          padding-top: 6px;
          padding-bottom: 6px;
          font-size: 15px;
          color: #3c3a3b;

          .ngb-highlight {
            color: #2c91d7;
          }
        }

        &.inactive-label {
          td {
            color: #8f8f8f;
          }
        }
      }
    }
  }
}

.pagination-wrapper {
  display: flex;
  align-content: center;
  align-items: center;

  .pagination-template {
    ul {
      margin-bottom: 0;

      .page-item {
        margin: 3px;

        .range-detail {
          padding: 0 10px;
          height: 100%;
        }

        .page-link {
          border-radius: 5px !important;
          padding: 7px 10px;

          &:not(input) {
            cursor: pointer;

            img {
              max-height: 15px;
              position: relative;
              top: 2px;
              vertical-align: initial;
            }
          }
        }

        input {
          max-width: 50px;
          text-align: center;
          color: #212529;
          padding-left: 3px;
          padding-right: 3px;
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
}

.init-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  align-content: center;
  background-color: #ffffff;

  span {
    margin-left: auto;
    margin-right: auto;
  }
}

.duplicate-error {
  .ng-select-container {
    border-color: #dc3545 !important;
  }
}

/** Snackbar overrides */
.custom-large-snackbar {
  max-width: none !important;
  min-width: auto !important;
  padding: 0 !important;
  border-radius: 4px;
}

/* Ng Select Override*/

.ng-select.ng-select-single .ng-select-container {
  height: 43px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding-left: 15px;
  font-size: 16px;
}

.ng-placeholder {
  color: #99afc2;
  font-style: italic !important;
  padding-left: 20px;
}

.ng-input {
  padding-left: 45px !important;
}

.ng-value {
  // padding-left: 35px;
  padding-left: 20px;
  padding-right: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 100% !important;
}

.ng-select.disable-arrow .ng-arrow-wrapper .ng-arrow {
  display: none;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}

/**
* named different from bootstrap since it can't be overriden.
*/
@each $width, $num in $border-width-list {
  .border-left-#{$num} {
    border-left-style: solid;
    border-left-width: $width;
  }

  .border-right-#{$num} {
    border-right-style: solid;
    border-right-width: $width;
  }

  .border-top-#{$num} {
    border-top-style: solid;
    border-top-width: $width;
  }

  .border-bottom-#{$num} {
    border-bottom-style: solid;
    border-bottom-width: $width;
  }

  .border-#{$num} {
    border-style: solid;
    border-width: $width;
  }
}

/**
* border radius scale
*/
@each $radius, $num in $radius-list {
  .rounded-#{$num} {
    border-radius: $radius;
  }

  .rounded-top-#{$num} {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }

  .rounded-bottom-#{$num} {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  .rounded-left-#{$num} {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }

  .rounded-right-#{$num} {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

/**
* letter space
*/
@each $letter-space, $word in $letter-space-list {
  .tracking-#{$word} {
    letter-spacing: $letter-space;
  }
}

/**
* font size scale
*/
@each $size, $num in $font-size-list {
  .font-size-#{$num} {
    font-size: $size;
  }
}

.flex-basis-0 {
  flex-basis: 0;
}


.tooltip-no-width {
  max-width: unset !important;
}

.mat-option.custom-typeahead-option {
  height: 55px;
}

ngb-pagination>.pagination {

  // spacing and shape of first child / previous button.
  .page-item:first-child {
    @extend .mr-4 !optional;

    .page-link {
      @extend .rounded-4;
    }
  }

  // spacing and shape of last child / next button.
  .page-item:last-child {
    @extend .ml-4 !optional;

    .page-link {
      @extend .rounded-4;
    }
  }

  .page-item {

    // bg-color of page buttons (not previous or next) to give shape to active page button.
    &:not(:first-child):not(:last-child) {
      @extend .bg-color-grey-200;
    }

    // styles of all page button itself including previous and next.
    .page-link {
      @extend .bg-color-grey-200;
      @extend .font-color-grey-300;
      @extend .border-0;
      @extend .font-weight-700;
    }
  }

  // first page number button shape.
  .page-item:nth-child(2) {
    @extend .rounded-left-full;
    @extend .pl-3 !optional;
  }

  // last page number button shape.
  .page-item:nth-last-child(2) {
    @extend .rounded-right-full;
    @extend .pr-3 !optional;
  }

  // active page button styles.
  .page-item.active {
    z-index: 2;

    .page-link {
      @extend .rounded-4;
      @extend .bg-color-primary-600;
      @extend .text-white;
    }
  }

  // get rid of element state styles that breaks other's styles.
  .page-item .page-link:hover,
  .page-item .page-link:active,
  .page-item .page-link:focus,
  .page-item .page-link {
    @extend .outline-none;
    z-index: initial;
    box-shadow: none;
  }
}

/**
* utility
*/
.decoration-none,
.decoration-none:focus,
.decoration-none:hover {
  text-decoration: none;
}

.outline-none,
.outline-none:focus,
.outline-none:active {
  outline: none;
}

.clickable {
  cursor: pointer;
}

.text-secondary-synlab {
  color: $synlab-secondary-text;
}

/** utility end */


/** */
.results,
.resultsList,
.resultsUserEditor,
.createaccount,
.resultsCheck {
  .mat-option.mat-option-multiple {
    padding-bottom: 7px;
    padding-top: 7px;
  }

  .mat-option-text {
    display: flex;
    color: #003765;
    flex-direction: column;

    * {
      line-height: normal;

      &.matchcode {
        font-weight: 500;
      }
    }
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);
      border: 1px solid #f2f5f7;
      font-size: 16px;
      position: relative;
      padding-top: 0 !important;
    }

    .mat-form-field-underline {
      display: none !important;
    }

    .mat-select-arrow-wrapper {
      top: 10px !important;
    }
  }

  .mat-select-search-panel {
    min-width: calc(100% + 150px) !important;
  }

  .btn-showmore {
    color: #003765;
  }

  .mat-chip {
    color: #0076ab;
    background-color: initial;
    border: 1px solid #0077ad;
    padding: 6px 15px;
    height: 35px;
    max-width: 250px;
    cursor: pointer;
    position: relative;
  }

  .mat-chip .material-icons {
    display: none;
  }

  .mat-chip.active {
    background-color: #0077ad !important;
    color: #fff !important;
  }

  .mat-chip.active .material-icons {
    display: block !important;
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 20px;
  }

  .mat-form-field .mat-form-field-infix {
    border-top: 5px solid transparent;
    padding: 0.7em 0 1em;
  }

  .mat-form-field .mat-form-field-outline {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);
  }

  .mat-form-field .mat-form-field-outline>* {
    border: 1px solid transparent;
  }

  .mat-form-field .mat-select-arrow-wrapper {
    top: 5px;
    position: relative;
  }

  .mat-form-field .mat-select-arrow-wrapper .mat-select-arrow {
    color: #023968;
  }

  .mat-form-field .mat-select-value-text,
  .mat-select-panel .mat-option-text {
    color: #003765;
  }

  td .mat-mdc-checkbox label {
    margin-bottom: 0 !important;
  }

  footer.new-footer {
    display: block !important;
  }
}

.no-padding {

  .mat-dialog-container,
  mat-dialog-container {
    padding: 0;

    .print-wrapper {
      width: 100%;
    }

    .ng2-pdf-viewer-container {
      overflow-x: hidden;
    }
  }
}

.results,
.resultsList,
.resultsUserEditor,
.resultsCheck {


  .mat-mdc-checkbox.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: #003867 !important;
    }
  }
}

/** logo styles adjustments */
.white-labeled-icon-dk {
  top: -3px !important;

  .navbar-brand {
    padding: 0 0;
    margin-right: 0 !important;

    .pl-logo-1 {
      max-height: 40px !important;
      top: 0 !important;
    }
  }
}

.content-scroller {
  .result-item {
    .b-name {
      .mat-mdc-checkbox .mdc-form-field {
        padding-bottom: 0 !important;
        margin: 0;
      }
    }
  }
}

/**
 styles for white-labeled version
*/
body.white-labeled-dk {
  overflow: auto;

  .s-container {
    overflow-y: initial;
    height: initial;
    padding-bottom: 0;
  }
}

.registerAndTest {
  .mat-snack-bar-container {
    margin-top: 72px;
    margin-right: 7px;
    background-color: #0077ad;
  }
}



.lab-order {
  &.mat-snack-bar-container {
    max-width: none !important;
    min-width: auto !important;
    padding: 0 !important;
    border-radius: 4px;

    .message-container {
      width: 97vw;
    }
  }
}

.mat-mdc-checkbox {
  .mat-label {
    color: $primary-blue-dark;
    font-size: 16px;
    font-weight: 400;
  }

  .mdc-checkbox__background {
    border-color: #bfddea !important;

    &:hover {
      border-color: $primary-blue-dark;
    }
  }
}

.mat-mdc-checkbox-checked {
  .mdc-checkbox__background {
    background-color: $primary-blue-dark !important;
  }
}

.toast-container>.toast-info {
  background-color: #397ea6;
}

@media only screen and (max-width: 992px) {
  .ng2-pdf-viewer-container {
    overflow-x: auto !important;
  }
}

.labOrder {

  .s-container,
  .page-wrapper {
    background-color: #fafbfc;
  }

  .mat-option-text {
    color: #495057 !important;
    font-size: 1rem !important;
  }

  .mat-select-arrow {
    color: #003765 !important;
  }

  .cdk-overlay-container {
    z-index: 1021 !important;
  }

  .mat-form-field {
    width: 100%;

    .mat-datepicker-toggle-default-icon {
      width: 2em !important;
    }

    .mat-form-field-wrapper {
      height: 45px;
      border: 1px solid #d4d9df;
      border-radius: 4px;
      overflow: hidden;
      padding-left: 10px;
      padding-right: 10px;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      padding-top: 2px;
      margin: 0;
    }
  }

  .mat-toolbar.mat-primary,
  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary,
  .mat-calendar-body-selected {
    background-color: #003765 !important;
  }

  .mat-dialog-actions {
    .mat-button {
      color: #003765;
    }
  }

  .toast-container {
    left: 15px;
    right: 15px;
    overflow: hidden;
    pointer-events: initial !important;

    button.toast-close-button {
      text-shadow: none !important;
    }

    .toast-title {
      font-weight: 500;
      font-size: 17px;
      padding-bottom: 3px;
    }

    .ngx-toastr {
      box-shadow: none !important;
      width: 100%;
      max-width: 400px;
      float: right;
      margin: 0 !important;

      &:hover {
        box-shadow: none !important;
      }

      &.toastr-info-container {
        overflow: visible !important;

        &::before {
          content: "";
          position: absolute;
          width: 4000px;
          width: 100vw;
          top: 0;
          bottom: 0;
          right: 0;
          background-color: #397ea6;
          z-index: 1;
        }

        &>* {
          position: relative;
          z-index: 3;
        }
      }
    }
  }
}

.resultsUserEditor {
  .cdk-overlay-container {
    z-index: 1021 !important;
  }

  .cdk-global-overlay-wrapper {
    margin-top: 0.75rem;
  }

  .s-container {
    padding-bottom: 0px !important;
  }
}

// styles for ngx-mat-timepicker
.ngx-mat-timepicker {
  border: 1px solid #d4d9df;
  border-radius: 3px;
  height: 43px !important;
  padding-bottom: 7px;
  padding-left: 8px;
  max-width: 175px;

  .mat-form-field {
    .mat-form-field-wrapper {
      border: 0;
      padding: 0;
    }
  }

  .ngx-mat-timepicker-control {
    width: 25px !important;
    min-width: auto !important;

    input {
      color: #495057 !important;
      font-size: 1rem !important;
    }
  }

  .mat-form-field-wrapper {
    height: auto !important;
    overflow: visible !important;
  }

  .arrows-wrap {
    right: -8px !important;
    display: none !important;

    svg {
      height: 15px !important;
    }
  }

  .separator-colon {
    margin-left: 3px !important;
    margin-right: 3px !important;
    right: 0px;
    top: 4px;
    position: relative;
  }

  .period-select {
    width: 45px !important;
    min-width: auto !important;
  }

  .ngx-mat-timepicker__toggle {
    .mat-icon {
      font-size: 22px !important;
      color: #003765;
    }
  }

  button.ngx-mat-timepicker-toggle {
    width: auto !important;
    height: auto !important;
    min-width: initial !important;

    &:active,
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    .mat-button-wrapper {
      padding: 0;
      margin: 0;
      position: relative;
      top: 7px;
    }
  }

  .mat-select-value-text {
    color: #495057 !important;
    font-size: 1rem !important;
  }
}

.hyRLSow3 {
  max-width: 160px;

  .mat-form-field-wrapper {
    height: 43px !important;
  }

  .mat-form-field-infix {
    width: auto !important;
  }

  .mat-icon {
    font-size: 18px !important;
    position: relative;
    top: -4px;
    color: #003765;
  }

  &.disabled {

    .ngx-mat-timepicker,
    .mat-form-field-wrapper {
      background-color: #e9ecef !important;
    }

    .mat-icon {
      opacity: 0.6 !important;
    }
  }

  input {
    color: #495057 !important;
    font-size: 1rem !important;
  }
}

.time-picker {
  .mat-form-field-label-wrapper {
    top: -15px !important;
    color: #495057;
  }
}

body.individualOrders,
body.generateOrders {
  .page-header-wrap {
    flex: none !important;
  }

  .barcode {
    margin-left: -10px;

    svg {
      width: 187px !important;
      height: 95px !important;

      g {
        rect {
          fill: #003765 !important;
        }
      }
    }
  }

  .message-box-success,
  .message-box-error {
    background-color: #63aa31;
    margin-top: 65px;
    margin-right: 0 !important;

    .icon {
      padding-right: 10px;
    }

    .message {

      h4,
      p {
        margin: 0;
      }

      h4 {
        font-size: 18px;
      }
    }
  }

  .message-box-error {
    background-color: #be1320;
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #003765 !important;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input[type="search"] {
    background-color: initial;
    cursor: auto;
    color: initial;

    &:disabled {
      background-color: #e9ecef;
    }
  }
}

body.generateOrders {
  .page-header-wrap {
    flex: none !important;
  }
}

.pdf-viewer {
  display: block;

  &--relative {
    .ng2-pdf-viewer-container {
      position: relative;
    }
  }
}

.iti {
  display: block !important;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open+input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.user-searchbar .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 0px;
}

app-results-v2 {
  * {
    font-family: "Open Sans", sans-serif !important;
  }

  .mdc-checkbox__checkmark-path {
    stroke: $secondary-yellow !important;
  }

  app-results-v2-category {
    >.title {
      font-size: 40px;
      line-height: 30px;
      color: $primary-blue;
      font-weight: 700;
      margin-bottom: 41px;
    }
  }

  app-results-v2-detail {
    .mat-tab-group {
      .mat-tab-label {
        margin-bottom: 24px !important;
      }
    }
  }

  .mat-drawer-container.closed .detail-header .action {
    position: absolute;
    margin-top: 0px;
    margin-left: -163px;
  }

  .content {
    .content-wrapper {
      max-width: 832px;
    }
  }

  .mat-drawer-container.closed {
    .content-wrapper {
      margin: auto;
    }
  }

  app-results-v2-single-test-detail {
    mat-card {
      padding: 40px !important;
      box-shadow: 0px 7px 30px -10px $primary-blue-card-box !important;
    }
  }

  .mat-drawer-side {
    border-right: 0px !important;
  }
}

.index-spinner.loader {
  display: none;
}

.spinner-border {
  color: $primary-skyblue;
}

.signin-redirecting {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
  }

  .loader::after,
  .loader::before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #F9B232;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }

  .loader::after {
    animation-delay: 1s;
  }

  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  app-root {
    display: none;
  }
}

.signin-redirecting .index-spinner.loader {
  display: initial;
}

.animate-fade-in {
  animation-name: fadeInKeyframes;
  animation-duration: 1s;
}

@keyframes fadeInKeyframes {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1023px) {
  app-results-v2-category {
    >.title {
      margin-top: 40px !important;
    }
  }
}

@import "./app/themes/media.scss";
@import "./app/themes/lab-order.scss";
@import "./app/themes/prevention-wellness.scss";
@import "./app/plasma-ui-common/synlab-access-ui-components/spinner/spinner.component.scss";

app-results-v2-panel-card:last-child mat-card {
  margin-bottom: 1px !important;
}

app-results-history .highcharts-tooltip-box {
  fill: #003765;
  stroke-width: 0;
}

app-results-history .highcharts-tooltip {
  .synlab-tooltip {
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fff;

    .row-tooltip {
      display: flex;
      justify-content: space-between;
    }

    .NORMAL_RANGE {
      display: none;
    }

  }


}

app-spinner.spinner-inline {
  position: relative;

  .loader,
  .loader::after,
  .loader::before {
    height: 29px;
    width: 29px;
  }
}

@media (max-width: 1023px) {
  .sidenav-container mat-drawer-container mat-drawer-content .content {
    width: 100% !important;
    padding: 20px !important;
    padding-top: 75px !important;
  }

  app-results-v2-category {
    display: block;
    margin-bottom: 55px;
  }

  .sidenav-container mat-drawer-container mat-drawer-content {
    app-results-v2-panel-card {
      .content {
        padding-top: 20px !important;
      }
    }
  }

  .results-tab-override {

    .mat-mdc-tab-labels,
    .mat-tab-labels {
      row-gap: 20px;
      column-gap: 10px;
      margin-bottom: 35px;

      .mdc-tab__text-label,
      .mat-tab-label-content {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

body.tenant-cz {
  ul.lib-header-menu {
    &.col-gap-0 {
      column-gap: initial !important;
    }
  }
}


body.tenant-fi {
  app-results-v2-indicator-card {
    .content {
      flex: initial;
      align-items: flex-start;
      width: 100%;

      &>div {
        display: block !important;

        &.title {
          width: 100%;
          max-width: 60%;
        }

        &.result {
          flex: 1;
          display: flex !important;
        }
      }
    }
  }
}

app-result-flag-label {
  .color-type-NO_TAG {
    display: none !important;
  }
}

.mat-mdc-checkbox-checked {
  .mdc-checkbox__background {
    border-color: #003867 !important;
    background-color: #003867 !important;
  }
}

.mdc-notched-outline__notch,
.mdc-notched-outline__trailing,
.mdc-notched-outline__leading {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.mdc-notched-outline__notch {
  border-left: none !important;
}

.mat-focused {

  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading {
    border-color: #0478ae !important;
  }
}

app-results-v2-filter {
  .mdc-text-field--outlined {
    &:hover {
      border-radius: 4px !important;
      outline: solid 2px black;
    }
  }
}

app-results-v2-detail-action {
  .mat-mdc-card-actions {
    a {
      .mdc-button__label {
        display: flex !important;
        gap: 8px !important;
        align-items: center !important;
        width: 100% !important;

        span {
          flex: 1;
          white-space: normal;
        }
      }
    }
  }

  .faecal-campaign {
    .mat-mdc-card-actions {
      max-width: 225px !important;
    }

    .mat-mdc-card-title {
      padding-right: 20px !important;
      font-size: 20px;
    }

    a .mdc-button__label {
      justify-content: center;

      span {
        flex: initial !important;
      }
    }
  }
}

app-results-v2-filter {
  .label {
    margin-bottom: 10px !important;
  }

  .mdc-button__label {
    color: white;
  }

  .filter-checkbox {
    margin-left: -12px;

    mat-checkbox {
      .mdc-form-field {
        height: 32px;

        .mdc-label {
          color: #003765;
          font-size: 16px;
          letter-spacing: normal;
          line-height: 24px;
          font-weight: 400;
          margin-left: -7px !important;
        }
      }
    }
  }
}

app-results-v2-detail-consultation {
  .mdc-button__label {
    display: flex;
  }
}

.mdc-checkbox {
  .mdc-checkbox__ripple {
    display: none !important;
  }
}


@media (min-width: 1196px) and (max-width: 1447px) {
  .sidenav-drawer {
    margin: 0 3vw !important;
  }
}

@media (min-width: 1448px) {
  mat-drawer {
    width: 500px;

    .sidenav-drawer {
      margin: 0 2vw !important;
    }
  }
}

mat-tab-header {
  button {
    display: none;
  }

  .mat-mdc-tab-labels {
    flex-wrap: wrap;
    gap: 0 !important;
  }
}

.color-type-NOT_NORMAL {
  white-space: nowrap;
}

.mdc-button.mat-primary {
  min-height: 52px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  height: unset !important;
}

.user-info-card-panel {
  .mdc-dialog__surface {
    padding: 24px;
  }

  .close {
    background: transparent;
    border: none;
  }
}

.transfer-dialog {
  .mat-mdc-dialog-surface {
    border-radius: 24px !important;
  }
}

.resultsCategory,
body[class*="resultsCategory"] {
  .results-tranfer-dialog {
    button.submit_transfer {
      border: 0 !important;
      font-weight: 500;
    }
  }

  app-transfer-confirm-dialog {
    mat-dialog-actions {
      display: block;
      padding: 20px 20px !important;
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      >* {
        border-radius: 64px;
        height: 42px;
        font-size: 14px;
        line-height: 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 25px;
        gap: 10px;
        border: 0px !important;
        font-weight: 500;
        color: #ffffff;
        background: #003765;
        min-width: 100px;
      }

      a {
        color: #003765 !important;
        background: #fff;
        border: 1px solid #BFDDEA !important;
        color: #4d7393;
      }
    }
  }
}

.display-none {
  display: none;
}

.suomi-ward-container {
  .mdc-radio__outer-circle {
    border-color: #0077ad !important
  }

  .mdc-radio__inner-circle {
    border-color: #003765 !important
  }

  .mdc-radio__background::before {
    background: transparent !important;
  }
}
