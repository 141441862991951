body.tenant-cz {
  .filter-container {
    .search-form-field img {
      width: 16px;
      height: 16px;
      right: 5px !important;
      top: 18px !important;

      &[alt="primary-close-icon"] {
        width: 30px;
        height: 30px;
        right: 6px !important;
        top: 4px !important;
      }
    }

    .mat-form-field-label-wrapper {
      left: 10px;
    }

    input.mat-input-element {
      padding-left: 10px !important;
    }
  }
}


@media only screen and (max-width: 1024px) {
  body.tenant-cz {
    .sidenav-container {
      .filter-title {
        img {
          width: 50px;
          height: 50px;
        }
      }
    }

    .consultation-card a[role=button],
    .consultation-card button {
      width: 252px !important;
    }

    .sidenav-container mat-drawer-container mat-drawer-content .content {
      padding-top: 0px;
      padding-bottom: 60px !important;

      &.scroll-content {
        padding-top: 76px!important;
      }

      .indicator-card-wrapper .content {
        padding-top: 30px !important;
      }
    }

    .indicator-card-wrapper .result {
      padding-bottom: 50px;
    }

    .indicator-card-wrapper .result .action,
    .panel-card-wrapper .content .icon {
      bottom: 20px;
      left: 20px;
      right: auto !important;
      top: auto !important;
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #f2f5f7;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        position: relative !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
        top: auto !important;
      }
    }
  }

  .scroll-content .content-wrapper {
    padding-top: 30px;
  }
}
