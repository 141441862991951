@import '../themes/variables';

@media only screen and (max-width: 1024px) {
  .minimized-filter {
    width: 100%;
    z-index: 10;
    border-radius: 50px;
    overflow: hidden;

    button {
      background-color: $primary-blue;
      width: 100%;
      margin: auto;
      border-radius: 50px;
      color: white;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      height: 40px;

      img {
        position: relative;
        top: -1px;
        margin-right: 10px;
      }
    }
  }

  .mat-drawer-container.closed .detail-header .action {
    position: inherit !important;
    margin-top: 10px;
    margin-left: 0px !important;
  }

  .sidenav-container {
    flex: 1 1 0;
    overflow: auto;

    mat-drawer .sidenav-drawer {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }

    .filter-container {
      margin-top: 76px;
    }

    .filter-title img {
      display: block !important;
    }

    .sidenav-drawer {
      padding: 24px;
    }
  }

  .mat-tab-labels {
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;

    & .mat-tab-label {
      margin: 0px 0px 0px 0px !important;
    }
  }

  app-results-v2-panel-card>.mat-card {
    padding: 16px !important;
    margin-top: 8px !important;
  }

  app-results-v2-panel-card {
    &:first-child>.mat-card {
      margin-top: 24px !important;
    }
  }

  .detail-header .action img {
    padding: 0 !important;
  }

  app-results-v2 app-results-v2-detail .mat-tab-group .mat-tab-label {
    margin-bottom: 0px !important;
  }

  .box {
    padding: 24px !important;
    overflow: auto;

    table {
      display: grid;
      grid-template-columns: 2fr 3fr;
      align-items: stretch;
      gap: 10px;

      tr {
        display: grid;
        align-items: center;
        height: 100%;
      }

      th,
      td {
        padding: 8px !important;
        width: 100% !important;
      }

      th {
        text-align: right;
      }

    }
  }

  app-results-v2-single-test-detail mat-card,
  app-results-v2 app-results-v2-single-test-detail mat-card,
  app-results-v2-single-test-detail-results mat-card,
  app-results-v2-detail-about mat-card {
    padding: 16px !important;
    margin: 0px;
  }

  .box,
  app-results-v2-detail-about mat-card.mat-card {
    padding: 16px !important;
  }

  .page-content-wrap {
    &.ee-tenant {
      background-color: #f2f8fb;

      .scroll-content {
        padding-top: 76px !important;
      }
    }
  }

  body.tenant-fi {
    app-results-v2-indicator {
      display: block;
      padding-bottom: 90px;
    }
  }

  body[class*="resultsDetail"]{
    app-results-v2-detail-lab-reports {
      table {
        caption {
          display: none !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .component-wrapper {
    min-height: 100%;
    position: relative;
  }

  .foreign-id-link {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .resultsList {
    .mat-select-search-panel {
      min-width: auto !important;
    }
  }

  .portugal-wrapper,
  .s-container {
    padding-bottom: 0 !important;
    background-color: #fafbfc;
  }

  .right-nav {
    width: 40% !important;

    .d-flex.mb-3.pt-3 {
      padding-top: 10px !important;
    }

    reset-btn {
      top: -6px;
    }
  }

  .content-scroller {
    .result-item {
      td.column-name {
        width: 300px !important;
      }
    }
  }

  .mobile-view {
    .card-header {
      .title {
        font-size: 16px !important;
        padding-left: 10px !important;
      }
    }
  }

  .foreign-id-link {
    display: block;
  }

  .white-labeled-dk {
    .new-footer {
      .ltext {
        font-size: 15px !important;
        padding-top: 10px;
        padding-bottom: 20px;
        text-align: center;
      }

      .logo-partners {
        img {
          max-height: none !important;
          display: block;
          margin: 0 auto;
          max-width: 150px;
          margin-bottom: 15px;

          &.logo3 {
            max-height: 90px !important;
          }
        }
      }

      .copyright {
        flex-direction: column;

        .links {
          a {
            display: block;
            //text-decoration: none !important;
            padding-top: 2px;
            padding-bottom: 2px;

            &:last-of-type {
              margin-bottom: 20px;
            }
          }
        }

        .logo-right {
          margin-left: initial !important;

          img {
            margin-top: 0 !important;
            max-height: 53px !important;
            position: relative;
            left: -8px;
          }
        }
      }
    }

    .navbar-brand {
      margin-left: 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) {

  .cancel-change-email-modal {
    min-width: auto;

    h4 {
      padding-top: 20px;
      text-align: center;
    }

    p {
      text-align: center;
    }

    .btn-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        width: 100% !important;
        max-width: 300px;
        margin-bottom: 10px;
        margin-left: 0 !important;
      }
    }

    .wrap-loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .right-nav {
    top: 80px;
    width: 50% !important;
  }

  .content-scroller {
    .result-item {
      td.column-name {
        width: 200px !important;
      }
    }
  }

  .white-labeled-dk {
    .new-footer {
      padding: 0 15px;

      .logo-partners {
        img {
          display: block;
        }

        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .copyright {
        flex-direction: column;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .logo-right {
          margin-left: initial !important;

          img {
            margin: 0 auto;
            margin-top: 0 !important;
          }
        }

        .links {
          word-break: break-word;
        }
      }
    }
  }
}

@media only screen and (max-width: 706px) {
  body.tenant-fi {
    app-results-v2-indicator-card {
      .content {
        &>div.title {
          max-width: 90% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .right-nav {
    width: 90% !important;
  }

  .content-scroller {
    .result-item {
      td.column-name {
        width: 155px !important;
      }
    }
  }

  .portugal-wrapper {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .right-nav {
    top: 80px;
    width: 90% !important;
  }

  .content-scroller {
    .result-item {
      td.column-name {
        width: 155px !important;
      }
    }
  }

  .white-labeled-dk {
    .new-footer {
      padding: 0 15px;

      .logo-partners {
        img {
          display: block;
        }

        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .copyright {
        flex-direction: column;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .logo-right {
          margin-left: initial !important;

          img {
            margin: 0 auto;
            margin-top: 0 !important;
          }
        }

        .links {
          word-break: break-word;
        }
      }
    }
  }

  .portugal-wrapper {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 400px) {
  html.cdk-global-scrollblock {
    top: 0 !important;
  }
}


@import "./cz-styles.scss";
