@import '../../app/themes/variables';

mat-tab-group {
    .mat-mdc-tab {
        transition: all ease-out .12s;
        background-color: transparent;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 16px;
        padding-left: 16px;
        border: 1px solid $primary-blue-border;
        border-radius: 50px;
        margin-right: 16px;
        margin-bottom: 16px;
        opacity: 1 !important;
        min-width: 60px !important;
        flex-grow: unset!important;
        overflow: hidden;

        &:hover {
            background: #D9EBF3;
            border: 1px solid #BFDDEA;
        }

        .mdc-tab__content {
            font-size: 16px;
            transition: all ease-out .12s;
            height: unset;
        }

        &::after {
            display: block;
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            left: 14px;
            background-color: $primary-skyblue;
            border-radius: 4px;
            opacity: 0;
            transition: all ease-out .12s;
            margin-top: 8px;
        }

        .mdc-tab__text-label {
            color: #4d7393;
        }

        &:hover {
            .mdc-tab__text-label {
                color: #4d7393!important;
            }
        }
    }

    .mat-mdc-tab.mdc-tab--active {
        background-color: $primary-blue-skyblue-15;
        border: 1px solid $primary-blue-border-dark;
        padding-left: 30px;
        position: relative;
        transition: all ease-out .12s;

        .mdc-tab__content {
            transition: all ease-in .12s;
        }
    }

    .mat-mdc-tab.mdc-tab--active::after {
        opacity: 1;
        transition: all ease-in .12s;
    }

    .mdc-tab-indicator__content--underline {
        display: none;
    }
}

mat-tab-header {
    .mat-mdc-tab {
        height: 42px!important;

        .mdc-tab__text-label {
            letter-spacing: normal;
        }
    }
}

@media screen and (max-width: 768px) {
    mat-tab-group {
        .mat-mdc-tab .mdc-tab__content {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 480px) {
    mat-tab-group {
        .mat-mdc-tab .mdc-tab__content {
            font-size: 16px;
        }
    }
}